import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Paragraph } from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import { Container } from "../elements/Layout";
import Button, { LinkButton } from "../elements/Button";
import { Categories, projects } from "../data/posts";
import ProjectCard from "../components/ProjectCard";
import theme from "../../config/theme";
import { Title } from "../elements/Text";
import { ImageBox } from "../elements/ImageBox";
import { CONTACT_PAGE } from "../../config/routes";
import { FadeInLeft, FadeInUp } from "../elements/Animations";

const ProjectsPage = styled.div`
  padding: 40px 0;

  .hero-section {
    margin-bottom: 60px;

    .image-wrapper {
      margin-bottom: 40px;
    }

    p {
      margin-top: 0;
      margin-bottom: 40px;
    }

    .image-wrapper {
      flex-basis: 100%;
      margin-bottom: 40px;
    }
  }

  .card {
    margin-bottom: 30px;
  }

  .buttons-row {
    display: none;
    margin-bottom: 60px;

    button {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .select-wrapper {
    margin-bottom: 40px;

    select {
      background: transparent;
      color: ${theme.colors.brand};
      padding: 12px;
      border: 1px solid ${theme.colors.brand};
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }
  }

  .tiles {
    max-width: 740px;
    margin: 0 auto;
    justify-content: center;
  }

  .title {
    align-self: center;
  }

  .d-none {
    display: none;
  }

  .responsive-justify {
    justify-content: center;
  }

  .gap {
    width: 40px;
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    .card {
      margin: 8px 8px 20px;
    }

    .tiles {
      justify-content: flex-start;
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding: 80px 0;

    .title {
      align-self: flex-start;
    }

    .hero-section {
      margin-bottom: 110px;

      p {
        max-width: 560px;
      }
    }

    .select-wrapper {
      display: none;
    }

    .buttons-row {
      display: flex;
    }

    .card {
      margin: 0 15px;
    }

    .d-lg-block {
      display: flex;
    }

    .d-lg-none {
      display: none;
    }

    .responsive-justify {
      justify-content: flex-start;
    }
  }

  @media (min-width: ${theme.breakpoints.xlarge}) {
    .tiles {
      max-width: 1080px;
    }
  }
`;

const cats = Object.values(Categories).filter(cat => cat !== Categories.MOBILE_APPS);

const Projects = ({ location }) => {
  const [filter, setFilter] = useState(Categories.ALL);
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    if (filter === Categories.ALL) {
      setTiles(projects);
      return;
    }
    setTiles(projects.filter(project => project.categories.includes(filter)));
  }, [filter]);

  function onFilterChange(name) {
    setFilter(name);
  }

  return (
    <ProjectsPage>
      <SEO title="Projects" location={location} />
      <section className="hero-section">
        <Container>
          <Box direction="row-responsive" align="center" basis="full">
            <FadeInLeft>
              <Box>
                <Title level="1" className="title" decorator margin={{ bottom: "medium" }}>
                  Projects
                </Title>

                <Box className="d-lg-none image-wrapper">
                  <StaticImage src="../images/projects-cover.jpg" alt="projects" placeholder="none" quality={90} />
                </Box>

                <Paragraph margin={{ vertical: "large" }}>
                  Take a look at our portfolio of case studies, starting simple websites, ending complicated measure
                  made CRM systems. All projects not only look good, but also satisfy our clients’ needs. We are happy
                  to create another website, mobile application, or e-commerce solution just for you!
                </Paragraph>

                <Box direction="row" flex={false} className="responsive-justify">
                  <LinkButton route={CONTACT_PAGE}>Contact us</LinkButton>
                </Box>
              </Box>
            </FadeInLeft>

            <div className="gap d-none d-lg-block" />

            <ImageBox className="d-none d-lg-block" align="end" maxWidth="600px">
              <StaticImage src="../images/projects-cover.jpg" alt="projects" placeholder="none" quality={90} />
            </ImageBox>
          </Box>
        </Container>
      </section>
      <section>
        <Container>
          <Box className="select-wrapper">
            <select onChange={e => onFilterChange(e.target.value)}>
              {cats.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </Box>
          <Box className="buttons-row" direction="row" wrap justify="center">
            {cats.map(category => (
              <Button
                key={category}
                className={filter === category ? "active" : ""}
                variant="outlined"
                onClick={() => onFilterChange(category)}
                label={category}
              />
            ))}
          </Box>
          <Box className="tiles" direction="row" wrap>
            {tiles.map(({ title, categories, thumbnail, route }) => (
              <FadeInUp key={Math.random()}>
                <ProjectCard
                  className="card"
                  key={title}
                  title={title}
                  image={thumbnail}
                  categories={categories}
                  route={route}
                />
              </FadeInUp>
            ))}
          </Box>
        </Container>
      </section>
    </ProjectsPage>
  );
};

export default Projects;
